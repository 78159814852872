import "./styles.scss";

export default function App() {
  return (
    <>
      <div class="bg-image"></div>
       <div class="Header">
        <div class="image">
          <img
            width="250"
            src="Images/LDCLogo.png"
          />
        </div>
        

        <a href="https://docs.google.com/document/d/1pQOjzGBMio7rOo69Yk_dc3VqZLgRVYoTA1zSG2cNGfg/edit?usp=sharing" target="_blank" id="saleAddress">
          <button id="buyBtn" class="button2">
            White Paper 
          </button>
          </a>
        <a href="https://www.facebook.com/LiberianDigitalCurrency" target="_blank" id="saleAddress">
          <button id="buyBtn" class="button2">
            JOIN OUR COMMUNITY
          </button>
        </a>
        <a href="https://forms.gle/Kj4VCY3FrvvZma5CA" target="_blank" id="application">
          <button id="buyBtn" class="button2">
            Click to apply
          </button>
        </a>
        <a href="https://forms.gle/QfaKecJqojgqKpg58" target="_blank" id="saleAddress">
          <button id="buyBtn" class="button2">
            Loan Application 
          </button>
          </a>

        <a href="https://wallet.liberiandigitalcurrency.com/" target="_blank" id="saleAddress">
          <button id="buyBtn" class="button2">
            Wallet 
          </button>
        </a>
      </div>

      <div class="main">
        <div class="block">
          <h1>Liberian Digital Currency (LDC)</h1>
          {/* <p>
            {" "}
            Brief information about token and Tokenomics INFO HERE 
            THE INFO BELOW is A EXAMPLE{" "}
          </p> */}

          <p>
            {" "}
            <h3>What is Liberian Digital Currency?</h3>
            Liberian Digital currency is here to answer a straightforward question! What if a country's currency was 100% in the hands of the people and completely digital? While not many may realize this, this is a start of a revolution! A revolution that will bring the people of Liberia out of poverty, slow the spread of disease, and bring a better quality of life. So we can slowly shift from the mindset of surviving mode to one that is of thriving mode. We live in a world where time is a commodity. Through a currency built from community involvement, we can align our perspectives with the people around us. Experience not only a change of pace but a better quality of life, and practice the acceptance of others. The success of a currency in the hands of a community depends on the shared strengths of everyone involved. This allows us to shift our perspectives to the perspective of those around us. Liberian Digital currency is our way of breaking down the paradigms and building a path to the future. A future worth living not only for today but living for tomorrow and building a tomorrow worth living for future generations to come. True power does not come from a single outlook. It comes from working together and is held together by hundreds, if not thousands, of interwoven moments. With that thought actively in our minds, We started developing the Liberian digital currency ecosystem.
          </p>
          <p>
            <h3>Why use Liberian Digital Currency?</h3>
            The built in tax system in LDC which works autonomously during every transaction  would help fund the LDC Fondation to make the much needed changes  for the Liberian people. While also preparing Liberians  for the future through a rewards/savings system. The People will be able to invest in  their futures simply by holding and using LDC. thus garnishing a reward in BNB, Ethernal, Bitcoin and Ethereum.
          </p>
          <h3>Are you a student?</h3>
          <p>The digital currency and foundation we are building has portfolio building features to help teach you how to invest and save for the future  along with built in mechanism to fund changes in Liberia that will bring more jobs, a better quality of living , better healthcare and even potentially a better education system  that could bring in more opportunities not only in your homeland but abroad as well!</p>
          <h3>Are you a business?</h3>
          <p>LDC is a currency you could accept in your business just like any other currency however LDC would make transacting with other countries a lot easier and faster allowing you to get supplies to fund your industry  cheaper and faster with less hassle</p>
        </div>

        <div class="block">
          <h1>How to buy</h1>

          <span id="addtokens" class="status err">
            <br />
            <h3>Contract Address: <p>0xfFd5cecDa5e574a128a36ff86af9be4a9d782877</p></h3>
            <h3>Anti Whale transaction limit: 8000 LDC <p> **CAN ONLY PURCHASE 8000 TOKENS PER TRANSACTION**</p></h3>
            <h3>Slippage: 5-7%</h3>

            <p>
              
            </p>
          </span>
          <p></p>
          <h3>
            How to Buy With Trust Wallet:{" "}
            <p>
              <a
                href="https://www.youtube.com/watch?v=uinGk6xX2j0"
                target="_blank"
                id="saleAddress"
              >
                <button id="buyBtn" class="button">
                  Click here
                </button>
              </a>
            </p>{" "}
          </h3>
          <h3>
            How to Buy With Metamask:
            <p>
              <a
                href="https://www.youtube.com/watch?v=KpF41eS3YZQ"
                target="_blank"
                id="saleAddress"
              >
                <button id="buyBtn" class="button">
                  Click here
                </button>
              </a>
              
            </p>
          </h3>
          <div class="buttons">
            {/* <a
              href="https://pancakeswap.finance/swap?outputCurrency=0xa3B8e1473AFbdD5c113a3B71fbD847bC42Aeb43E#/swap?outputCurrency=0xa3B8e1473AFbdD5c113a3B71fbD847bC42Aeb43E"
              target="_blank"
              id="saleAddress"
            >
              <button id="buyBtn" class="raise">
                BUY ON PANCAKESWAP
              </button>
            </a> */}
            {/* <a
              href="https://app.Liberian Digital Currency.club"
              target="_blank"
              id="saleAddress"
            >
              <button id="buyBtn" class="raise">
                Liberian Digital Currency Dashboard
              </button>
            </a> */}
            {/* <a
              href="https://poocoin.app/tokens/0xa3B8e1473AFbdD5c113a3B71fbD847bC42Aeb43E"
              target="_blank"
              id="saleAddress"
            >
              <button id="buyBtn" class="raise">
                PRICE CHARTS
              </button>
            </a> */}
            <div class="image2">
          <img
            width="250"
            src="Images/LDCLogo.png"
          />
        </div>
          </div>
        </div>
      </div>

      <div class="main">
        <div class="block block1">
          <h1>About the LDC foundation</h1>
          <p>
            The Foundation will create jobs for over 786,000 Liberian citizen, to achieve this goal we will use the LDC cryptocurrency as a leverage. 
            While utilizing the power of blockchain we can bring a better rainwater management system to Liberia, a cleaner and safer cistern for drinkable water. 
            We will be able to provide a more efficient medical care system using LDC as the official currency accepted for health services at the facilities.
            The foundation will improve the educational system by employing better teachers who would be getting paid through the LDC foundation. We would also provide a stipend for every government official which they would receive not only during their time in office, but also during their time of retirement.
</p>
          
          
        </div>
      </div>
      <div class="main">
        <div class="block block1">
          <h1>Tokenomics</h1>
          <p>
          <h3> Total Supply: 8 million </h3>
           <h3> Total Tax: 4%</h3>
           <h3> 50% of tax goes to funding LDC Foundation To build a better future for Liberia</h3>
           <h3> 20% of the tax goes to liquidity to help keep the market floor price be more stable </h3>
           <h3> 20% of the tax goes to building a portfolio for holders</h3>
           <h3> 5%  of the tax goes to gas refund to help cover network cost for holders!</h3>
           <h3> 3%  of the tax goes to auto buyback keeping the price rising and giving more value  for the holders </h3>
           <h3> 2%  of the tax goes to lottery pot </h3>

            </p>
          
            
        </div>
        
        
      </div>
      
    </>
  );
}
